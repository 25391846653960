@keyframes colorFun {
    0% {color: var(--blue);}
    50% {color: var(--green);}
    100% {color:var(--orage);}
    
}

@keyframes textShadowFun {
    0% {text-shadow: 2px 1px var(--blue);}
    50% {text-shadow: 2px 1px var(--green);}
    100% {text-shadow: 2px 1px var(--orage);}

}

:root {
    --red: #AA1155;
    --orage: #D45113;
    --white: white;
    /* OLD --white: #F1E4F3; */
    --green: #94C9A9;
    --blue: #2C666E;
}

/* a, a:visited {
    color: rgb(131, 0, 94);
    text-decoration: none;
}
 */
a>h2:hover {
    animation-name: colorFun;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.card-container {
    width: 80vw;
    height: 50vh;
    position: relative;
}

.card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: textShadowFun;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
}

.carouselBtn {
    position: absolute;
    z-index: 2;
    background: none;
    border: none;
    font-size: 4rem;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, .5);
    cursor: pointer;
    border-radius: .25rem;
    padding: 0 .5rem;
    background-color: rgba(0, 0, 0, .1);
}

.carouselBtn:hover, 
.carouselBtn:focus {
    color: white;
    background-color: rgba(0, 0, 0, .2);
}

.carouselBtn:focus {
    outline: 1px solid black;
}

.carouselBtn.prev{
    left: -5rem;
}

.carouselBtn.next {
    right: -5rem;
}


.dot {
    width: 1rem;
    height: 1rem;
    background-color: var(--blue);
    border-radius: 3rem;

}

.dot[data-active] {
    border: 2px var(--green) solid;
    background-color: var(--red);
}

.dotsContainer {
    z-index: 3;
    position: relative;
    /* bottom: -25rem; */
    bottom: -95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.externalLink {
    width: 1.5rem;
    /*For Orange->*/
    /* filter: invert(33%) sepia(88%) saturate(1014%) hue-rotate(350deg) brightness(99%) contrast(97%); */
    /*For Red-> filter: invert(12%) sepia(84%) saturate(3966%) hue-rotate(321deg) brightness(94%) contrast(96%); */
}

h2{
    grid-area: h2;
}

img{
    width: 40vw;
}

.newlyUpdated{
    position: absolute;
    text-shadow: none;
    top: 0;
    left: 0;
    margin: 0; 
    padding: .5em; 
    border-radius: .25em;
    transform: rotate(-20deg) rotateY(20deg);
    background: var(--red);
    text-align: center;
    width: 25vw;
}
.newlyUpdated:after{
    position: absolute;
    z-index: -1;
    content: '';
    top: 0; right: 0; bottom: 0; left: 0;
    border-radius: inherit;
    transform: rotate(2deg) translate(.35em, -.15em) scale(1.02);
    background: var(--orage);
}

.updating{
    position: relative;
}

.updating>div>p{
    position: relative;
    left: -10%;
}

.updating>div>div{
    position: relative;
    left: -10%;
}

.noncarousel{
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.off{
    background-color: #AA1155;
}

.on{
    background-color: #94C9A9;
}

p{
    grid-area: p;
    padding-left: 2vw;
    padding-right: 2vw;
}

.project-area{
    /* height: 100vh; */
    flex: 1 0 auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-area>h1 {
    color: var(--blue);
    text-shadow: 2px 2px 2px var(--green);
}

.project-card {
    padding-top: 3vh;
    padding-left: 1vw;
    display: grid;
    grid-template-areas: 
    "img img h2" 
    "img img p" 
    "img img p" ;
    position: absolute;
    /* place-items: center; */
    inset: 0;
    opacity: 0;
    transition: 200ms opacity ease-in-out;
    transition-delay: 200ms;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.project-card[data-active]{
    opacity: 1;
    z-index: 1;
    transition-delay: 0ms;
}

.updating-card{
    padding-top: 3vh;
    padding-left: 1vw;
    display: grid;
    grid-template-areas: 
    "img img h2" 
    "img img p" 
    "img img p" ;
    position: absolute;
}

.pills-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.pill{
    font-size: large;
    border-radius: 3vw;
    padding: .3rem
}

#Express{
    background-color: black;
    color: white
}

#Firebase{
    background-color: #dd2c00;
    color:white
}

#Graphs{
    background-color: #8d8d8d;
}

#JavaScript{
    background-color: #ed3a4e;
    color: white;
}

#MongoDB{
    background-color: #00ed64;
}

#Node{
    background-color: #57a745;
}

#Passport{
    background-color: #35df79;
}

#Railway{
    background-color: #843acd;
    color: white
}

#React{
    background-color: #58c4dc;
}

#TypeScript{
    background-color: #3178c6;
    color: white;
}

.project-card-list{
    margin-left: 5vw;
    margin-right: 5vw;
    padding-top: 3vh;
    padding-left: 1vw;
    padding-bottom: 3vh;
    display: grid;
    grid-template-areas: 
    "img img h2" 
    "img img p" 
    "img img div" ;
    position: relative;
    inset: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.screenshot{
    display:grid;
    grid-area: img;
    border-radius: 2%;
    align-self: center;
}

.toggle{
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5vw;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#ktScreenshot {
    width: 60%;
    padding-left: 25%;
}

@media screen and (max-width: 800px) {
    .carouselBtn {
            position: absolute;
            z-index: 2;
            background: none;
            border: none;
            font-size: 4rem;
            top: 110%;
            transform: translateY(50%);
            color: rgba(255, 255, 255, .5);
            cursor: pointer;
            border-radius: .25rem;
            padding: 0 .5rem;
            background-color: rgba(0, 0, 0, .1);
    }

    .carouselBtn.prev {
        left: 0;
    }

    .carouselBtn.next {
        right: 0;
    }

    .dotsContainer {
        bottom: -112%;
    }

    #ktScreenshot{
        padding-left: 0%;
    }

    .newlyUpdated{
        position: relative;
        text-shadow: none;
        top: -45vh;
        left: -25vw;
        margin: .5em auto; 
        padding: .5em; 
        border-radius: .25em;
        transform: rotate(-20deg) rotateY(20deg);
        background: var(--red);
        text-align: center;
    }
    .newlyUpdated:after{
        position: absolute;
        z-index: -1;
        content: '';
        top: 0; right: 0; bottom: 0; left: 0;
        border-radius: inherit;
        transform: rotate(2deg) translate(.35em, -.15em) scale(1.02);
        background: var(--orage);
    }

.project-area>p{
    padding-left: 5vw;
    padding-right: 5vw;
}
    .project-card {
            height: 56vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            inset: 0;
            opacity: 0;
            transition: 200ms opacity ease-in-out;
            transition-delay: 200ms;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    }

    .screenshot {
        border: 2px solid rgb(0, 110, 255);
        border-radius: 1%;
        width: 80%;
    }

    
}