:root {
    --red: #AA1155;
    --orage: #D45113;
    --white: white;
    /* OLD --white: #F1E4F3; */
    --green: #94C9A9;
    --blue: #2C666E;
}

#bottom {
    margin-bottom: 2rem;
    font-size: small;
}

.footer{
    background-color: var(--red);
    color: var(--white);
    width: 90%;
    text-align: left;
    padding-top: 10vh;
    flex-shrink: 0;
    padding-left: 10%;
}

.footer>h1{
    text-shadow: 2px 1px var(--blue);
}

@media screen and (max-width:800px) {
.footer {
        padding-left: 5vw;
        padding-right: 5vw;
        width: 90vw;
    }
}