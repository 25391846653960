@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --red: #AA1155;
    --orage: #D45113;
    --white: white;
    /* OLD --white: #F1E4F3; */
    --green: #94C9A9;
    --blue: #2C666E;
}

.header{
    background-color: var(--white);
    z-index: 3;
    display: flex;
    height: 10vh;
    align-items: center;
    justify-content: space-between;
}

.link {
    color: var(--blue);
    text-shadow: 2px 2px var(--green);
}

.linkContainer {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10%;
}

.meContainer {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    padding-left: 10%;
}

.pfp {
    height: 3rem;
    width: auto;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%
}

@media screen and (max-width:800px){
    .name{
        font-size: 0;
    }

    .pfp{
        height: 3rem;
    }
}