:root {
  --red: #AA1155;
  --orage: #D45113;
  --white: white;
  /* OLD --white: #F1E4F3; */
  --green: #94C9A9;
  --blue: #2C666E;
}

.App {
  text-align: center;

    background-color: var(--white);
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: calc(10px + 2vmin);
      color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #fb61a6;
}