:root {
    --red: #AA1155;
    --orage: #D45113;
    --white: white;
    /* OLD --white: #F1E4F3; */
    --green: #94C9A9;
    --blue: #2C666E;
}

.bio{
    display: flex;
    background-color: var(--blue);
    align-items: center;
}

.bioText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    /* padding-top: 10vh; */
    height: 100vh;
    color: var(--white);
    padding-left: 15vw;
    padding-right: 15vw;

}

.bioText>h2 {
    text-shadow: 2px 2px var(--red);
}


.external-bio-links{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 2vw;
    padding-right: 3vw;
}

li>a>.externalLink {
    width: 1.5rem;
    /*For Orange->*/ /* filter: brightness(0) saturate(100%) invert(33%) sepia(88%) saturate(1014%) hue-rotate(350deg) brightness(99%) contrast(97%); */
    /* For Red->  *//* filter: brightness(0) saturate(100%) invert(12%) sepia(84%) saturate(3966%) hue-rotate(321deg) brightness(94%) contrast(96%); */
    /*For White->*/ filter: brightness(0) saturate(100%) invert(91%) sepia(8%) saturate(595%) hue-rotate(254deg) brightness(105%) contrast(90%);
}

.externalLink:hover{
    filter: brightness(0) saturate(100%) invert(33%) sepia(88%) saturate(1014%) hue-rotate(350deg) brightness(99%) contrast(97%);
}

.headshot{
    margin-left: 10vw;
    width: 20vw;
    background-color: var(--green);
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 var(--orage);
    /* box-shadow: 0 4px 8px 0 var(--orage), 0 6px 20px 0 var(--red); */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* border: 1px solid var(--red); */
}

li {
    list-style: none;
}

.nameAndPic {
    display: flex;
    justify-content: center;
}

@media screen and (max-width:800px) {
    .bio {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: var(--blue);
            padding-top: 10vh;
            height: 110vh;
        }
    .headshot{
        margin-top: 10vh;
        margin-right: 7vw;
        width: 40vw;
    }
    .external-bio-links{
        justify-content: center;
        padding: 0;
    }
    ul {
        padding-inline-start: 0;
    }
}